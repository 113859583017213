module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.nhsstaffsurveys.com/wp/graphql","develop":{"nodeUpdateInterval":300,"hardCacheMediaFiles":false,"hardCacheData":false},"type":{"Category":{"exclude":true},"Comment":{"exclude":true},"ContentNode":{"exclude":true},"ContentType":{"exclude":true},"Post":{"exclude":true},"PostFormat":{"exclude":true},"Tag":{"exclude":true},"Taxonomy":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"TermNode":{"exclude":true},"MediaItem":{"localFile":{"maxFileSizeBytes":134217728,"excludeByMimeTypes":[],"requestConcurrency":100},"createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NHS Staff Surveys","short_name":"NHS Staff Surveys","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"content/assets/nhs-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"73fd7c78b48fe0d44c0853947ffe8a4c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5NCF35L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
