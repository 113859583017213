import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { updateResponsiveMode } from "./../../redux/actions"
import { MOBILE, DESKTOP } from "./../../utils/constants"

// Redux
const mapDispatchToProps = {
  updateResponsiveModeAction: updateResponsiveMode,
}

const WidthSetter = connect(
  null,
  mapDispatchToProps
)(({ width, updateResponsiveModeAction, children }) => {
  const setResponsiveMode = () => {
    if (width < 1024) {
      return MOBILE
    }

    return DESKTOP
  }

  useEffect(() => {
    updateResponsiveModeAction(setResponsiveMode(width))
  })

  return <>{children}</>
})

const Responsive = ({ children }) => {
  const [width, setWindowWidth] = useState(0)

  useEffect(() => {
    updateDimensions()

    window.addEventListener("resize", updateDimensions)

    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  const updateDimensions = () => {
    const newWidth = window.innerWidth
    setWindowWidth(newWidth)
  }

  return (
    <>
      <WidthSetter width={width}>{children}</WidthSetter>
    </>
  )
}

export default Responsive

Responsive.propTypes = {
  children: PropTypes.element.isRequired,
}
