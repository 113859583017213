// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-contact-js": () => import("./../../../src/components/templates/contact.js" /* webpackChunkName: "component---src-components-templates-contact-js" */),
  "component---src-components-templates-generic-js": () => import("./../../../src/components/templates/generic.js" /* webpackChunkName: "component---src-components-templates-generic-js" */),
  "component---src-components-templates-homepage-js": () => import("./../../../src/components/templates/homepage.js" /* webpackChunkName: "component---src-components-templates-homepage-js" */),
  "component---src-components-templates-interactive-results-js": () => import("./../../../src/components/templates/interactive-results.js" /* webpackChunkName: "component---src-components-templates-interactive-results-js" */),
  "component---src-components-templates-local-results-js": () => import("./../../../src/components/templates/local-results.js" /* webpackChunkName: "component---src-components-templates-local-results-js" */),
  "component---src-components-templates-results-archive-js": () => import("./../../../src/components/templates/results-archive.js" /* webpackChunkName: "component---src-components-templates-results-archive-js" */),
  "component---src-components-templates-results-js": () => import("./../../../src/components/templates/results.js" /* webpackChunkName: "component---src-components-templates-results-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

