import {
  UPDATE_PAGE_URI,
  UPDATE_RESPONSIVE_MODE,
  DESKTOP,
  UPDATE_MOBILE_MENU_MODE,
} from "../utils/constants"

const initialState = {
  pageURI: "/",
  responsiveMode: DESKTOP,
  mobileMenuOpen: false,
}

function reducer(state = initialState, action) {
  // console.log("reducer", state, action)
  switch (action.type) {
    case UPDATE_PAGE_URI:
      return {
        ...state,
        pageURI: action.payload,
      }
    case UPDATE_RESPONSIVE_MODE:
      return {
        ...state,
        responsiveMode: action.payload,
      }
    case UPDATE_MOBILE_MENU_MODE:
      return {
        ...state,
        mobileMenuOpen: action.payload,
      }
    default:
      return state
  }
}

export default reducer
