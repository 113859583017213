import {
  UPDATE_PAGE_URI,
  UPDATE_RESPONSIVE_MODE,
  UPDATE_MOBILE_MENU_MODE,
} from "../utils/constants"

export function updatePageURI(payload) {
  return {
    type: UPDATE_PAGE_URI,
    payload,
  }
}

export function updateResponsiveMode(payload) {
  return {
    type: UPDATE_RESPONSIVE_MODE,
    payload,
  }
}

export function updateMobileMenuMode(payload) {
  return {
    type: UPDATE_MOBILE_MENU_MODE,
    payload,
  }
}
