import React from "react"
import { Provider } from "react-redux"
import store from "./src/redux/stores"

import Responsive from "./src/components/layouts/responsive"

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <Responsive>{element}</Responsive>
    </Provider>
  )
}
